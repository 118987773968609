<template>
    <section v-scrollanimation class="highlights p-84 mt-68">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <section class="section-header">
                        <h2>U nás nájdete </h2>
                    </section>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="highlight">
                        <likeIcon/>
                        <p>Individuálny prístup</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="highlight">
                        <historyIcon/>
                        <p>Komplexné služby</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="highlight">
                        <calendarIcon/>
                        <p>Dostupnosť 7 dní v týždni</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import likeIcon from '../assets/icons/thumbs-up-solid.svg'
import historyIcon from '../assets/icons/history-solid.svg'
import calendarIcon from '../assets/icons/calendar-alt-solid.svg'
export default {
    name: 'Highlights',
    components:{
        likeIcon, historyIcon, calendarIcon
    }
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.highlights{
    @include highlights;
}
</style>