<template>
  <main>
    <section class="about">
      <div class="title">
        <h1>O nás</h1>
      </div>
    </section>
    <Aboutus/>
    <section v-scrollanimation class="gridgallery p-84 mt-68">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <section class="section-header">
              <h2>Pozrite si naše priestory</h2>
            </section>
          </div>
        </div>
      </div>
    </section>
    <div v-scrollanimation class="gridgallery-img" >
      <div class="gridgallery-content" @click="openGallery(index)" v-for="(image, index) in media" :key="index">
        <div class="gridgallery-overlay" ></div>
        <img :src="image.src" alt="Naše priestory" title="Naše priestory"/>
        <div class="gridgallery-details">
          <p>Ukážka</p>
        </div>
      </div>
    </div>
    <Highlights/>
    <LightBox
        ref="lightbox"
        :media="media"
        :show-caption="true"
        :show-light-box="false"
      />
  </main>
</template>

<script>
import Aboutus from '../components/Aboutus-text'
import LightBox from 'vue-it-bigger'
import Highlights from '../components/Highlights.vue'
export default {
  name: 'About',
  components:{
    Aboutus, LightBox, Highlights
  },
  data(){
		return{
			media: [
        {src: require('../assets/img/1.jpg')},
        {src: require('../assets/img/2.jpg')},
        {src: require('../assets/img/3.jpg')},
        {src: require('../assets/img/4.jpg')},
        {src: require('../assets/img/5.jpg')},
        {src: require('../assets/img/6.jpg')},
      ]
		}
	},
  methods: {
		openGallery(index) {
			this.$refs.lightbox.showImage(index)
		}
  }
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";
@import "../assets/scss/_lightbox";
@import "../assets/scss/_gridgallery";


.about{
  @include overlay;
  @include views-title;
}
.workplace{
  .section-header{
    h2{
      @include section-header;
    }
  }
}



</style>
